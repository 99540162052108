<script>
import { mapActions, mapState } from 'vuex'
import ResetPasswordModal from '@/components/05-pages/profile/reset_password_modal.vue'
import jwtDecode from 'jwt-decode'

export default {
  components: { ResetPasswordModal },
  data: () => ({

  }),
  async created () {
    const { idInstitution } = this.userLocalData
    await this.A_GET_INSTITUTION(idInstitution)
  },
  computed: {
    ...mapState({
      S_INSTITUTION: state => state.Institution.S_INSTITUTION
    }),
    userLocalData () {
      return jwtDecode(this.$cookies.get('AUTH_TOKEN')).user
    },
    formatDate () {
      const [year, month, day] = this.S_INSTITUTION.createdAt.split('T')[0].split('-')
      return `${day}/${month}/${year}`
    }
  },
  methods: {
    ...mapActions({
      A_GET_INSTITUTION: 'Institution/A_GET_INSTITUTION'
    }),
    openModalResetPassword () {
      this.$modal.show('reset-password-modal')
    },
    returnPage () {
      this.$router.go(-1)
    }
  }
}
</script>
<template lang="pug">
  .container
    .header
      .title Perfil
    .body
      .box
        .title {{ S_INSTITUTION.name }}
        .description {{ S_INSTITUTION.initials }}
        .description Fecha de ini. {{ formatDate }}
      .box.row-flex.center-y
        .title Color
        input(
          type="color"
          :value="S_INSTITUTION.color"
        )
      .sub-content
        .box(style="width:48%")
          .title Acceso:
          input.input-text(
            type="text"
            :value="userLocalData.username"
            disabled
          )
          input.input-text(
            type="password"
            value="**************"
            disabled
          )
          .e-btn.e-btn--fullwidth.btn(
            @click="openModalResetPassword"
          ) cambiar contraseña
        .box(style="width:48%")
          .title Logo:
          img(
            :src="S_INSTITUTION.logo"
          )
    ResetPasswordModal
</template>

<style lang="scss" scoped>
  $color: #236192;
  $secondary-color: #E0E0E0;
  $color3: #3284ef;
  .container {
    display:flex;
    width: 700px;
    padding: 20px;
    flex-flow: column nowrap;
    .header {
      display: flex;
      flex-flow: row nowrap;
      margin: 0 0 20px 0;
      align-items: center;
    }
    .title {
      font-weight: 500;
      color: $color;
      font-size: 25px;
      align-self: center;
      margin: 0 10px 0 0 !important;
    }
  }
  .btn {
      background: $color;
      border-radius: 50px;
  }
  .container_icon {
    padding: 10px;
    border-radius: 50%;
    background: $color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
    cursor: pointer;
    .icon_return {
      transform: rotate(180deg);
      color:white;
    }
  }
  .body {
    display: flex;
    flex-flow: column nowrap;
    * {
      margin: 10px 0;
    }
    .box {
      border: 2px solid $secondary-color;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 0;
    }
  }
  .sub-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .row-flex {
    display: flex;
    flex-flow: row nowrap;
    &.center-x {
      justify-content: center;
    }
    &.center-y {
      align-items: center;
    }
  }
  .description {
    color: $color3;
    font-size: 16px;
  }
  .input-text {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    background: rgb(230,230,230);
  }
</style>
