<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    newPassword: '',
    newPasswordCopy: '',
    lastPassword: '',
    incorrectLastPassword: false
  }),
  computed: {
    isCorrectlyPassword () {
      if (this.newPassword.length > 6) {
        return true
      }
      return false
    },
    isEqualsPassword () {
      if (this.newPassword === this.newPasswordCopy) return true
      return false
    }
  },
  methods: {
    ...mapActions({
      A_VERIFY_PASSWORD: 'Auth/A_VERIFY_PASSWORD',
      A_RESET_PASSWORD: 'Auth/A_RESET_PASSWORD'
    }),
    closeModalSuccessfull () {
      this.$modal.hide('successfull-change-password')
    },
    clearInputs () {
      this.newPasswordCopy = ''
      this.lastPassword = ''
      this.newPassword = ''
      this.incorrectLastPassword = false
    },
    async changePassword () {
      try {
        if (this.isCorrectlyPassword && this.isEqualsPassword) {
          const isCorrectPassword = await this.A_VERIFY_PASSWORD(this.lastPassword)
          if (!isCorrectPassword) {
            this.TOAST('Contraseña incorrecta', null, 500)
            this.incorrectLastPassword = true
            return
          }
          await this.A_RESET_PASSWORD(this.newPassword)
          this.incorrectLastPassword = false
          this.TOAST('Contraseña cambiado exitosamente', null, 200)
          this.$modal.hide('reset-password-modal')
          this.$modal.show('successfull-change-password')
        } else {
          console.log('El formato de la contraseña es invalido')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<template lang="pug">
  div
    modal(
    name="reset-password-modal"
    class="c-modal"
    :shiftY="0.2"
      height="auto"
      :scrollable="true"
    )
      .container
        .title Cambiar contraseña
        .description Se recomienda usar una contraseña segura que no uses en ningún otro sitio, con un minimo de 6 caracteres.
        .input-group
          label Contraseña actual
          input(
            type="text"
            v-model="lastPassword"
          )
          p.text-warning(
            v-if="incorrectLastPassword"
          ) Contraseña incorrecta
        .input-group
          label Nueva contraseña
          input(
            type="text"
            v-model="newPassword"
          )
          p.text-warning(
            v-if="!isCorrectlyPassword && newPassword.length > 0"
          ) Minimo 6 caracteres
        .input-group
          label Repetir nueva contraseña
          input(
            type="text"
            v-model="newPasswordCopy"
          )
          p.text-warning(
            v-if="!isEqualsPassword"
          ) Las contraseñas no coinciden
        a ¿olvidaste tu contraseña?
        button.submit-button(
          @click="changePassword"
        ) Guardar cambios
    modal(
      name="successfull-change-password",
      class="c-modal"
      :shiftY="0.2"
      height="auto"
      :scrollable="true"
    )
      .container
        button.submit-button(
          @click="closeModalSuccessfull"
        ) Continuar
</template>

<style lang="scss" scoped>
  $primary_blue: rgba(0, 106, 182, 1);
  $primary_black: rgba(64, 64, 64, 1);
  $primary_red: #FF5656;
  .container {
    width: 100%;
    height: max-content;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    overflow: visible;
    .title {
      color: $primary_blue;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 10px 0;
      width: 100%;
    }
    .description {
      color: $primary_black;
      font-weight: 500;
      font-size: 15px;
      margin: 0 0 30px 0;
      line-height: 20px;
    }
    a {
      color: $primary_blue;
      cursor: pointer;
      font-size: 17px;
      margin: 10px 0 20px 0;
      &:hover {
        text-decoration: underline;
      }
    }
    .input-group {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      width: 95%;
      align-items: center;
      label {
        color: $primary_black;
        font-weight: 400;
        font-size: 14px;
        width: 35%;
        white-space: nowrap;
        text-align: right;
        margin: 0 10px 0 0;
      }
      input {
        padding: 10px;
        width: 60%;
        background: rgb(230,230,230);
        border-radius: 5px;
      }
      .text-warning {
        margin: 5px;
        color: $primary_red;
        font-family: Bw Nista Grotesk;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        width: 60%;
      }
    }
    .submit-button {
        background: $primary_blue;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 17px;
      }
  }
  .container-success {
    width: 300px;
    height: auto;
  }
</style>
